import { useAppSelector } from '@/redux/typedHooks';
import { useAppQuery } from './useAppQuery';
import { useEffect } from 'react';
import useActions from './useActions';
import type User from '@/types/User';

const useGetUser = () => {
  const { token } = useAppSelector((state) => state.auth);
  const { updateUser } = useActions();
  const { data } = useAppQuery<{ data: User }>(`user`, {
    url: `v1/user`,
  });

  useEffect(() => {
    if (data && token) updateUser(data.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, token]);
};

export default useGetUser;
