import type { ComponentPropsWithoutRef } from 'react';
import { theme } from 'twin.macro';

const CheckFill = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 12.2451C22 14.8973 20.9464 17.4408 19.0711 19.3162C17.1957 21.1915 14.6522 22.2451 12 22.2451C9.34784 22.2451 6.8043 21.1915 4.92893 19.3162C3.05357 17.4408 2 14.8973 2 12.2451C2 9.59295 3.05357 7.04941 4.92893 5.17405C6.8043 3.29869 9.34784 2.24512 12 2.24512C14.6522 2.24512 17.1957 3.29869 19.0711 5.17405C20.9464 7.04941 22 9.59295 22 12.2451ZM17.0375 8.45762C16.9482 8.36864 16.8419 8.29858 16.7249 8.25164C16.6079 8.20469 16.4827 8.18181 16.3567 8.18438C16.2306 8.18695 16.1064 8.2149 15.9914 8.26658C15.8765 8.31825 15.7731 8.39258 15.6875 8.48512L11.3463 14.0164L8.73 11.3989C8.55228 11.2333 8.31722 11.1431 8.07435 11.1474C7.83147 11.1517 7.59974 11.2501 7.42797 11.4218C7.25621 11.5936 7.15782 11.8253 7.15353 12.0682C7.14925 12.3111 7.2394 12.5461 7.405 12.7239L10.7125 16.0326C10.8016 16.1216 10.9077 16.1916 11.0245 16.2387C11.1413 16.2857 11.2663 16.3088 11.3922 16.3064C11.5181 16.3041 11.6422 16.2764 11.7571 16.2251C11.8721 16.1738 11.9755 16.0998 12.0612 16.0076L17.0513 9.77012C17.2214 9.59324 17.3153 9.35671 17.313 9.11131C17.3107 8.86592 17.2122 8.63122 17.0387 8.45762H17.0375Z"
        fill={`${theme`colors.jungleGreen.DEFAULT`}`}
      />
    </svg>
  );
};

export default CheckFill;
