import { Link } from 'react-router-dom';

/**
 * Not Found Page component
 *
 * @returns jsx
 */
const SomethingWentWrong = ({ handleReportError }) => {
  return (
    <section className="flex h-screen flex-col items-center justify-center bg-ebonyclay text-white">
      <h1 className="mb-2.5 font-butler text-70 font-medium leading-84 md:text-120 md:leading-144 xl:text-180 xl:leading-216">
        Oops!
      </h1>
      <p className="mb-[60px] font-lato text-base leading-5 md:text-18 md:leading-snug xl:text-24 xl:leading-29">
        Something went wrong.{' '}
        <span
          className="cursor-pointer text-jungleGreen underline"
          onClick={handleReportError}
        >
          Report Error.
        </span>
      </p>
      <Link
        to="/"
        className="hover-linear-background-transparent rounded border border-jungleGreen px-68 py-4 font-butler text-18 font-medium uppercase leading-snug md:px-90 lg:px-107"
        onClick={() => {
          window.location.href = '/';
        }}
      >
        Go Home
      </Link>
    </section>
  );
};

export default SomethingWentWrong;
