import { useAppSelector } from '@/redux/typedHooks';
import { Outlet, Navigate } from 'react-router-dom';
import useGetStarted from '../hooks/useGetStarted';
import FullPageLoader from './Loaders/FullPageLoader';

const PublicRoutes = () => {
  const { token } = useAppSelector((state) => state.auth);
  const { showGetStarted, isLoading } = useGetStarted();

  if (!token) return <Outlet />;

  if (isLoading) return <FullPageLoader />;

  return <Navigate to={showGetStarted ? '/get-started' : '/dashboard'} />;
};

export default PublicRoutes;
