const Dash = ({ color = '#fff', styleClass = '' }) => {
  return (
    <svg
      width="24"
      height="2"
      viewBox="0 0 24 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${styleClass} mr-2.5 inline`}
    >
      <path d="M0 1H24" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default Dash;
