import type { ComponentPropsWithoutRef } from 'react';

const KudyPointsIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9997 8.57129V19.9999M5.14258 11.9999H18.8569V17.7141C18.8569 18.3204 18.616 18.9017 18.1874 19.3304C17.7587 19.759 17.1774 19.9999 16.5712 19.9999H7.42829C6.82208 19.9999 6.2407 19.759 5.81205 19.3304C5.38339 18.9017 5.14258 18.3204 5.14258 17.7141V11.9999Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.57143 8.57148H12V6.28576C12 4.57148 9.464 4.25033 8.57143 5.14291C7.67886 6.03548 7.67886 7.67891 8.57143 8.57148ZM15.4286 8.57148H12V6.28576C12 4.57148 14.536 4.25033 15.4286 5.14291C16.3211 6.03548 16.3211 7.67891 15.4286 8.57148ZM5.14286 8.57148H18.8571C19.1602 8.57148 19.4509 8.69189 19.6653 8.90621C19.8796 9.12054 20 9.41123 20 9.71433V10.8572C20 11.1603 19.8796 11.451 19.6653 11.6653C19.4509 11.8796 19.1602 12 18.8571 12H5.14286C4.83975 12 4.54906 11.8796 4.33474 11.6653C4.12041 11.451 4 11.1603 4 10.8572V9.71433C4 9.41123 4.12041 9.12054 4.33474 8.90621C4.54906 8.69189 4.83975 8.57148 5.14286 8.57148V8.57148Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default KudyPointsIcon;
