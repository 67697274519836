import { configureStore } from '@reduxjs/toolkit';
import authSlice from './features/auth/authSlice';
import modalSlice from './features/modal/modalSlice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    modal: modalSlice.reducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
