import { useEffect } from 'react';

const useEscapeKey = <T>(
  handleCloseFunc: (...args: T[]) => void,
  ...functionArgs: T[]
) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        if (functionArgs.length === 0) {
          handleCloseFunc();
        } else if (
          functionArgs.length === 1 &&
          typeof functionArgs[0] === 'object'
        ) {
          handleCloseFunc(functionArgs[0]);
        } else {
          handleCloseFunc(...functionArgs);
        }
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
    // eslint-disable-next-line
  }, []);
};

export default useEscapeKey;
