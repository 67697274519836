import scrollToTopOnDashboard from '@/utils/scrollToTop';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GlobalScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTopOnDashboard();
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default GlobalScrollToTop;
