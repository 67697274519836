import { lazy } from 'react';
import OverviewIcon from '@/components/Icons/OverviewIcon';
import FundsIcon from '@/components/Icons/FundsIcon';
import GetStartedIcon from '@/components/Icons/GetStartedIcon';
import ProfileIcon from '@/components/Icons/ProfileIcon';
import TransactionsIcon from '@/components/Icons/TransactionsIcon';
import KreditIcon from '@/components/Icons/KreditIcon';
import KudyPointsIcon from '@/components/Icons/KudyPointsIcon';
import { useFeature } from 'flagged';
import useKredit from '../hooks/useKredit';
import useGetStarted from '../hooks/useGetStarted';
import FullPageLoader from '../components/Loaders/FullPageLoader';
import PortfolioIcon from '@/components/Icons/PortfolioIcon';

const GetStarted = lazy(() => import('../pages/GetStarted/GetStarted'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const UserProfile = lazy(() => import('../pages/UserProfile/UserProfile'));
const Funds = lazy(() => import('../pages/Funds/Funds'));
const Portfolio = lazy(() => import('../pages/Portfolio/Portfolio'));
const Transactions = lazy(() => import('../pages/Transactions/Transactions'));
const KudyKredit = lazy(() => import('../pages/Kredit/Kredit'));
const KudyPoint = lazy(() => import('../pages/KudyPoint/KudyPoint'));

const useSideMenuRoutes = () => {
  const isKudyPointsEnabled = useFeature('kudyPoints');
  const { showKreditMenu, isLoading: isLoadingKredit } = useKredit();
  const { showGetStarted, isLoading: isLoadingGetStarted } = useGetStarted();

  const menu = [
    {
      title: 'Get Started',
      Icon: GetStartedIcon,
      to: '/get-started',
      Component: isLoadingGetStarted ? FullPageLoader : GetStarted,
      enabled: isLoadingGetStarted ? true : showGetStarted,
    },
    {
      title: 'Overview',
      Icon: OverviewIcon,
      to: '/dashboard',
      Component: Dashboard,
      enabled: true,
    },
    {
      title: 'Profile',
      Icon: ProfileIcon,
      to: '/profile',
      Component: UserProfile,
      enabled: true,
    },

    {
      title: 'Funds',
      Icon: FundsIcon,
      to: '/funds',
      Component: Funds,
      enabled: true,
    },
    {
      title: 'My Portfolio',
      Icon: PortfolioIcon,
      to: '/portfolio',
      Component: Portfolio,
      enabled: true,
    },
    {
      title: 'Kudy Kredit',
      Icon: KreditIcon,
      to: '/kredit',
      Component: isLoadingKredit ? FullPageLoader : KudyKredit,
      enabled: isLoadingKredit ? true : showKreditMenu,
    },
    {
      title: 'Kudy Points',
      Icon: KudyPointsIcon,
      to: '/kudypoint',
      Component: KudyPoint,
      enabled: isKudyPointsEnabled,
    },
    {
      title: 'Transactions',
      Icon: TransactionsIcon,
      to: '/transactions',
      Component: Transactions,
      enabled: true,
    },
  ] as const;

  return menu;
};

export default useSideMenuRoutes;

export type SideMenuRouteProps = ReturnType<typeof useSideMenuRoutes>;
