import type { ComponentPropsWithoutRef } from 'react';

const ProfileIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.0778 12.9407L18.5345 14.5119C19.427 14.9176 20 15.8075 20 16.7878V17.5C20 18.8807 18.8807 20 17.5 20H6.5C5.11929 20 4 18.8807 4 17.5V16.7878C4 15.8075 4.57301 14.9176 5.46549 14.5119L8.92216 12.9407C7.75209 12.0255 7 10.6006 7 9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9C17 10.6006 16.2479 12.0255 15.0778 12.9407ZM9.96127 13.5668L5.8793 15.4223C5.3438 15.6657 5 16.1996 5 16.7878V17.5C5 18.3284 5.67157 19 6.5 19H17.5C18.3284 19 19 18.3284 19 17.5V16.7878C19 16.1996 18.6562 15.6657 18.1207 15.4223L14.0387 13.5668C13.4161 13.8452 12.7261 14 12 14C11.2739 14 10.5839 13.8452 9.96127 13.5668ZM12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ProfileIcon;
