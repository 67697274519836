import { Component } from 'react';
import * as Sentry from '@sentry/browser';
import SomethingWentWrong from './Error';

/**
 * Dashboard component
 * @returns jsx
 */
export default class ErrorBoundary extends Component {
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
  };

  /**
   * getDerivedStateFromError
   * @param {Object} error error
   * @returns object
   */
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  /**
   * componentDidCatch
   * @param {Object} error error
   * @param {Object} errorInfo errorInfo
   */
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo });
    });
  }

  /**
   * render method
   * @returns jsx
   */
  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <SomethingWentWrong
          handleReportError={() =>
            Sentry.showReportDialog({ eventId: this.state.eventId })
          }
        />
      );
    }
    return this.props.children;
  }
}
