import { useAppSelector } from '@/redux/typedHooks';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const { token } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  const publicURLs = ['/', '/login', '/register'];

  if (token && publicURLs.includes(pathname)) {
    return null;
  }

  return (
    <footer className="relative w-screen bg-ebonyclay p-3.5 text-center text-10 uppercase text-white md:text-13">
      <p>
        &copy;{new Date().getFullYear()}. kudy financials s.a.r.l. all rights
        reserved.
      </p>
    </footer>
  );
};

export default Footer;
