import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { ReactNode } from 'react';

type ModalState = {
  modalComponent: ReactNode | null;
  promptComponent: ReactNode | null;
  isModalOpen: boolean;
  scrollable: boolean;
  isAppMenu: boolean;
};

const initialState: ModalState = {
  modalComponent: null,
  promptComponent: null,
  isModalOpen: false,
  scrollable: false,
  isAppMenu: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    promptOpened(state, action: PayloadAction<ReactNode>) {
      state.promptComponent = action.payload;
    },
    promptClosed(state) {
      state.promptComponent = null;
    },
    modalRegistered(_, action: PayloadAction<ModalState>) {
      return action.payload;
    },
    modalUnregistered() {
      return initialState;
    },
  },
});

export const {
  promptOpened,
  promptClosed,
  modalRegistered,
  modalUnregistered,
} = modalSlice.actions;
export default modalSlice;
