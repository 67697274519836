import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { theme } from 'twin.macro';

const ToastContainerWrapper = ({ className }: { className?: string }) => (
  <ToastContainer className={className} />
);

const CustomToastContainer = styled(ToastContainerWrapper)`
  &&& {
    min-width: 288px;
    width: fit-content;
    padding: 0;
    margin-left: 1rem;
    @media (min-width: 720px) {
      min-width: 376px;
    }
    @media (max-width: 480px) {
      margin: 8px;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  .Toastify__toast {
    padding: 12px 16px;
    border: 1px solid;
    border-radius: 4px;
    min-height: fit-content;
    box-shadow: none;
  }
  .Toastify__toast--error {
    border-color: ${theme`colors.valencia.DEFAULT`};
    background-color: ${theme`colors.valencia.10`};
  }
  .Toastify__toast--success {
    border-color: ${theme`colors.jungleGreen.DEFAULT`};
    background-color: ${theme`colors.jungleGreen.10`};
  }
  .Toastify__toast-body {
    padding: 0;
    gap: 16px;
  }
  .Toastify__toast-icon {
    width: fit-content;
    margin-inline-end: 0;
  }
`;

export default CustomToastContainer;
