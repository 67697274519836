import { useAppSelector } from '@/redux/typedHooks';
import './Modal.scss';
import useActions from '../../hooks/useActions';
import AppMenuModal from './AppMenuModal';
import SideModal from './SideModal';
import { useLocation } from 'react-router-dom';
import isDecember from '../../utils/isDecember';
import {
  modalRegistered,
  modalUnregistered,
} from '../../redux/features/modal/modalSlice';
import isNGDomain from '@/utils/isNGDomain';
import AppMenuModalNG from './AppMenuModalNG';

const body = document.querySelector('body');
let boundAnimationEnded;

const settings = {
  color: '#fffafa',
  snowCount: Math.random() * (500 - 150) + 150,
};

const Modal = () => {
  const {
    isAppMenu,
    isModalOpen: isOpen,
    modalComponent: component,
    scrollable,
  } = useAppSelector((state) => state.modal);

  const { close: closeModal } = useActions();

  const { token } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  const publicURLs = ['/', '/login', '/register'];

  const commonModalProps = {
    isOpen,
    isDecember,
    closeModal,
    settings,
    component,
  };

  return isAppMenu && isNGDomain ? (
    <AppMenuModalNG {...commonModalProps} />
  ) : isAppMenu ? (
    <AppMenuModal {...commonModalProps} />
  ) : token && publicURLs.includes(pathname) ? null : (
    <SideModal {...commonModalProps} scrollable={scrollable} />
  );
};

const onAnimationEnd = (cb, dispatcher) => {
  const modal = document.querySelector('.modal');

  body.classList.remove('disable-scroll');
  modal.removeEventListener('animationend', boundAnimationEnded);
  setTimeout(() => {
    if (cb) cb();
    dispatcher(modalUnregistered());
  }, 50);
};

export const open = (component, scrollable = false, isAppMenu = false) => {
  body.classList.add('disable-scroll');

  return modalRegistered({
    modalComponent: component,
    isModalOpen: true,
    scrollable,
    isAppMenu,
  });
};

export const close = (cb, dispatcher, isAppMenu = false) => {
  const modal = document.querySelector('.modal');

  boundAnimationEnded = onAnimationEnd.bind(null, cb, dispatcher);
  modal.addEventListener('animationend', boundAnimationEnded);

  if (isAppMenu && isNGDomain) {
    modal.classList.add('exit');
  } else if (isAppMenu) {
    const modalLinks = document.querySelectorAll('.modal-slideInUp');
    modalLinks.forEach((node) => {
      node.classList.remove('modal-slideInUp');
      node.classList.add('modal-slideOutDown');
    });
  } else {
    modal.classList.add('exit');
  }
};

export default Modal;
