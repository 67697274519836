import { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Button } from './Button';
import styled from 'styled-components';
import FeedbackIcon from './Icons/FeedbackIcon';
import { theme } from 'twin.macro';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  color-scheme: only light;
  --foreground: var(--feedback-fg, ${theme`colors.ebonyclay`});
  --background: var(--feedback-bg, #ffffff);

  .widget__actor {
    position: fixed;
    z-index: 100000;
    margin: var(--feedback-margin, 15px);
    inset: auto 0 0 auto;

    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 16px;

    font-size: 14px;
    font-weight: 600;
    line-height: 1.14em;
    text-decoration: none;

    background: var(--background);
    border-radius: 1.7em/50%;
    border: 0.5px solid ${theme`colors.loblolly`};
    box-shadow: 0px 4px 24px 0px rgba(43, 34, 51, 0.12);
    color: var(--foreground);
    fill: var(--foreground);
    cursor: pointer;
    opacity: 1;
    transition: transform 0.2s ease-in-out;
    transform: translate(0, 0) scale(1);
  }

  .widget__actor[aria-hidden='true'] {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transform: translate(0, 16px) scale(0.98);
  }

  .widget__actor:hover {
    background: var(--background);
    filter: brightness(95%);
  }

  .widget__actor svg {
    width: 1.14em;
    height: 1.14em;
  }

  @media (max-width: 600px) {
    .widget__actor span {
      display: none;
    }
  }
`;

type Feedback = {
  attachTo(el: Element | string | null): () => void;
};

function SentryFeedbackButton() {
  const [feedback, setFeedback] = useState<Feedback>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonHidden, setButtonHidden] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    setFeedback(Sentry.getFeedback());
  }, []);

  useEffect(() => {
    if (feedback && buttonRef.current) {
      const unsubscribe = feedback.attachTo(buttonRef.current);
      return unsubscribe;
    }
    return () => {};
  }, [feedback]);

  useEffect(() => {
    const feedBackElement = document.querySelector('#sentry-feedback');
    const shadowRoot = feedBackElement?.shadowRoot;
    const dialogElement = shadowRoot?.querySelector('.dialog');
    const formElement = dialogElement?.querySelector(
      '.form__right'
    ) as HTMLElement | null;

    if (formElement && buttonHidden) {
      formElement.style.flex = '1 1 0%';
    }

    if (dialogElement && buttonHidden) {
      setIsDialogOpen(true);
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'open'
          ) {
            const isDialogOpen = !!dialogElement.hasAttribute('open');
            setIsDialogOpen(isDialogOpen);
          }
        }
      });

      observer.observe(dialogElement, { attributes: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [buttonHidden]);

  useEffect(() => {
    if (!isDialogOpen) {
      setButtonHidden(false);
    }
  }, [isDialogOpen]);

  const { pathname } = useLocation();
  if (pathname.includes('statement')) return null;

  return (
    <Container>
      <Button
        className="widget__actor group"
        ref={buttonRef}
        icon={<FeedbackIcon />}
        onClick={() => setButtonHidden(true)}
        aria-hidden={buttonHidden ? 'true' : 'false'}
      >
        <span className="max-w-0 overflow-hidden whitespace-nowrap transition-all duration-300 ease-linear group-hover:max-w-xs">
          <span className="pl-2"> Report an issue</span>
        </span>
      </Button>
    </Container>
  );
}

export default SentryFeedbackButton;
