import LoaderIcon from '../Icons/LoaderIcon';

const FullPageLoader = () => (
  <div className="fixed left-0 top-0 z-50 h-screen w-screen bg-ebonyclay">
    <div className="absolute left-1/2 top-1/4 -ml-[125px]">
      <LoaderIcon />
      <div className="font-butler text-24 uppercase text-white">
        Kudy Financials Sarl
      </div>
    </div>
  </div>
);

export default FullPageLoader;
