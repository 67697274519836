import type User from '@/types/User';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

type DecodedToken = {
  exp: number;
};

const clearLocalStorage = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('lastVisible');
  localStorage.removeItem('pop-bannerClosed-temp');
};

const isTokenExpired = (token: string) => {
  const { exp } = jwtDecode<DecodedToken>(token);
  const isExpired = exp < Date.now() / 1000;
  if (isExpired) {
    clearLocalStorage();
  }
  return isExpired;
};

const getToken = () => {
  const token = localStorage.getItem('jwtToken');
  return token && !isTokenExpired(token) ? token : null;
};

const getUser = () => {
  const user = localStorage.getItem('user');
  return user ? (JSON.parse(user) as User) : {};
};

type AuthState = {
  user: User | Record<string, never>;
  token: string | null;
};

const initialState: AuthState = {
  user: getUser(),
  token: getToken(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userUpdated(state, action: PayloadAction<User>) {
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.user = { ...state.user, ...action.payload };
    },
    userLoggedIn(state, action: PayloadAction<{ token: string }>) {
      const { token } = action.payload;
      localStorage.setItem('jwtToken', token);
      state.token = token;
    },
    userLoggedOut(state) {
      clearLocalStorage();
      state.user = {};
      state.token = null;
    },
  },
});

export const { userUpdated, userLoggedIn, userLoggedOut } = authSlice.actions;
export default authSlice;
