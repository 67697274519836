import type { ComponentPropsWithoutRef } from 'react';
import kudyLogo from '../assets/images/kudyLogo.png';
import kudyLogoChristmas from '../assets/images/kudyLogoChristmasEdition.png';
import twMerge from '../utils/twMerge';
import isDecember from '@/utils/isDecember';

type KudyLogoProps = {
  className?: string;
} & ComponentPropsWithoutRef<'div'>;

const KudyLogo = ({ className, ...props }: KudyLogoProps) => {
  return (
    <div className={twMerge('h-[43px] w-[39px]', className)} {...props}>
      <img src={isDecember ? kudyLogoChristmas : kudyLogo} alt="logo" />
    </div>
  );
};

export default KudyLogo;
