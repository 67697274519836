import type { ComponentPropsWithoutRef } from 'react';

const GetStartedIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 5H19V4.5C19 3.67157 18.3284 3 17.5 3H6.5C5.67157 3 5 3.67157 5 4.5V5ZM4 5.5V4.5C4 3.11929 5.11929 2 6.5 2H17.5C18.8807 2 20 3.11929 20 4.5V5.5C20 5.77614 19.7761 6 19.5 6H4.5C4.22386 6 4 5.77614 4 5.5ZM4 10.5V7.5C4 7.22386 4.22386 7 4.5 7H19.5C19.7761 7 20 7.22386 20 7.5V10.5C20 10.7761 19.7761 11 19.5 11H4.5C4.22386 11 4 10.7761 4 10.5ZM5 10H19V8H5V10ZM6 15.5V12.5C6 12.2239 6.22386 12 6.5 12H21.5C21.7761 12 22 12.2239 22 12.5V15.5C22 15.7761 21.7761 16 21.5 16H6.5C6.22386 16 6 15.7761 6 15.5ZM7 15H21V13H7V15ZM4 18.5V17.5C4 17.2239 4.22386 17 4.5 17H19.5C19.7761 17 20 17.2239 20 17.5V18.5C20 19.8807 18.8807 21 17.5 21H6.5C5.11929 21 4 19.8807 4 18.5ZM5 18V18.5C5 19.3284 5.67157 20 6.5 20H17.5C18.3284 20 19 19.3284 19 18.5V18H5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GetStartedIcon;
