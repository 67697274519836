import type { ComponentPropsWithoutRef } from 'react';

const FundsIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 5V5.5C6 6.88071 4.88071 8 3.5 8H3V12H3.54138C4.92209 12 6.04138 13.1193 6.04138 14.5V15H18V14.5C18 13.1193 19.1193 12 20.5 12H21V8H20.5C19.1193 8 18 6.88071 18 5.5V5H6ZM5 5H4.5C3.67157 5 3 5.67157 3 6.5V7H3.5C4.32843 7 5 6.32843 5 5.5V5ZM5.04138 15V14.5C5.04138 13.6716 4.36981 13 3.54138 13H3V13.5C3 14.3284 3.67157 15 4.5 15H5.04138ZM5.72515 16C5.66827 16.0225 5.60627 16.0349 5.54138 16.0349C5.4765 16.0349 5.4145 16.0225 5.35761 16H4.5C3.11929 16 2 14.8807 2 13.5V6.5C2 5.11929 3.11929 4 4.5 4H19.5C20.8807 4 22 5.11929 22 6.5V13.5C22 14.8807 20.8807 16 19.5 16H5.72515ZM19 15H19.5C20.3284 15 21 14.3284 21 13.5V13H20.5C19.6716 13 19 13.6716 19 14.5V15ZM19 5V5.5C19 6.32843 19.6716 7 20.5 7H21V6.5C21 5.67157 20.3284 5 19.5 5H19ZM12 14C9.79086 14 8 12.2091 8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10C16 12.2091 14.2091 14 12 14ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13ZM2.5 18C2.22386 18 2 17.7761 2 17.5C2 17.2239 2.22386 17 2.5 17H21.5C21.7761 17 22 17.2239 22 17.5C22 17.7761 21.7761 18 21.5 18H2.5ZM2.5 20C2.22386 20 2 19.7761 2 19.5C2 19.2239 2.22386 19 2.5 19H21.5C21.7761 19 22 19.2239 22 19.5C22 19.7761 21.7761 20 21.5 20H2.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FundsIcon;
