import axios from 'axios';

const createAxiosInstance = () => {
  const token = localStorage.getItem('jwtToken');

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      token,
      accept: 'application/json',
      'Content-type': 'application/json; charset=UTF-8',
    },
  });

  return axiosInstance;
};

export default createAxiosInstance;
