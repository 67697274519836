import { useAppSelector } from '@/redux/typedHooks';
import { AnimatePresence } from 'framer-motion';

const Prompt = () => {
  const { promptComponent } = useAppSelector((state) => state.modal);

  return (
    <AnimatePresence>{promptComponent && promptComponent}</AnimatePresence>
  );
};
export default Prompt;
