import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '641acb83-c081-4e54-a8dc-011d5d6e0090',
    clientToken: 'pub6ab068579304be187980ee0a9135ac8c',
    site: 'datadoghq.eu',
    service: 'web',
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    env: process.env.NODE_ENV,
  });
  datadogLogs.init({
    clientToken: 'pub6ab068579304be187980ee0a9135ac8c',
    site: 'datadoghq.eu',
    service: 'web',
    forwardErrorsToLogs: false,
  });

  datadogRum.startSessionReplayRecording();
}
