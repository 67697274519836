import { Link, useLocation } from 'react-router-dom';
import Line from '../Line';
import Dash from '../Icons/Dash';
import { useAppSelector } from '@/redux/typedHooks';
import getSupportEmail from '@/utils/getSupportEmail';

/**
 * Contact component
 *
 * @returns jsx
 */
const Contact = () => {
  const { token } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();

  if (token && pathname === '/') {
    return null;
  }

  return (
    <div className="relative w-screen text-white">
      <section
        id="contact"
        className="overflow-auto bg-ebonyclay px-5 md:px-34 lg:px-47 xl:px-90 2xl:px-120"
      >
        <div className="mt-10">
          <Dash />
          <span className="font-light uppercase">Say Hi</span>
        </div>

        <div className="mt-5 pb-10 font-butler text-44 uppercase md:text-48 xl:text-130">
          Contact
        </div>

        <Line />

        <div className="pb-10 md:flex md:items-start lg:justify-around">
          <div className="mb-30 text-16 md:mr-8">
            <p className="mb-2.5 font-semibold uppercase text-jungleGreen md:mb-4">
              Luxembourg
            </p>

            <p className="text-silver2">
              Kudy Financials S.à.r.l. BP 027 West Side Village 2 C Parc d{"'"}
              Activités Capellen L-8308 Luxembourg.
            </p>
          </div>

          <div className="mb-30 text-16 md:mr-8">
            <p className="mb-2.5 font-semibold uppercase text-jungleGreen md:mb-4">
              United Kingdom
            </p>

            <p className="text-silver2">
              1 Scott Place, 2 Hardman Street, Manchester, M3 3AA, United
              Kingdom
            </p>
          </div>

          <div className="hover-underline-jungleGreen mx-auto mb-10 py-1 text-center font-butler md:ml-0 md:mr-45 md:self-center md:text-24 lg:text-32 xl:text-40">
            <span className="block">Say Hi</span>
            <a href={`mailto:${getSupportEmail()}`}>{getSupportEmail()}</a>
          </div>
        </div>

        <Line />
      </section>

      <section className="overflow-auto bg-ebonyclay px-5 pt-30 md:flex md:flex-wrap md:items-center md:justify-start md:px-34 lg:px-47 xl:px-90 xl:pb-210 2xl:px-120">
        <div className="mb-30 md:w-1/3 lg:mr-5">
          <p className="mb-2.5 text-18 uppercase xl:text-32">
            Kudy financials s.a.r.l
          </p>
          <p className="text-16 text-silver2 md:mb-23">
            A financial sanctuary.
          </p>
        </div>

        <div className="pb-20 md:w-270 xl:w-auto">
          <p className="mb-2.5 font-semibold uppercase text-jungleGreen md:mb-4">
            Company
          </p>

          <ul className="list-none text-12 text-silver2 md:text-base">
            <li className="mb-2.5 block pb-1 xl:mr-5">
              <Link className="hover-underline-jungleGreen py-1" to="/terms">
                Terms of service
              </Link>
            </li>
            <li className="mb-2.5 block pb-1 xl:mr-5">
              <Link className="hover-underline-jungleGreen py-1" to="/privacy">
                Privacy and policy
              </Link>
            </li>
            <li className="mb-2.5 block pb-1 xl:mr-5">
              <Link
                className="hover-underline-jungleGreen py-1"
                to="/anti-money"
              >
                Anti-money laundering policy
              </Link>
            </li>
            <li>
              <Link className="hover-underline-jungleGreen py-1" to="/faq">
                FAQ
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Contact;
