import { useAppSelector } from '@/redux/typedHooks';
import { useAppQuery } from './useAppQuery';
import type KreditAllowance from '@/types/KreditAllowance';
import type TotalKredit from '@/types/TotalKredit';
import type Kredit from '@/types/Kredit';
import { useFeature } from 'flagged';
import { useExchange } from './useExchange';

const useKredit = () => {
  const { user, token } = useAppSelector((state) => state.auth);
  const isKreditFlagEnabled = useFeature('kudyKredit');

  const { buy, sell } = useExchange();
  const exchangeRateDifference = Math.abs(buy - sell);
  const maxAllowedSpread = 0.1 * buy;
  const disableKredit = exchangeRateDifference > maxAllowedSpread;

  let isKreditEnabled =
    !buy || !sell ? false : isKreditFlagEnabled && !disableKredit;

  const { data: allowance, isLoading: isLoadingAllowance } =
    useAppQuery<KreditAllowance>(
      `user-kudykredit-allowance-${user.id}`,
      {
        url: `v1/user/kudykredit/allowance`,
      },
      { enabled: !!token && !!isKreditEnabled }
    );

  const { data: kreditBalance, isLoading: isLoadingKreditBalance } =
    useAppQuery<TotalKredit>(`user-kudykredit-total-${user.id}`, {
      url: `v1/user/kudykredit`,
    });

  const { data: allKredit, isLoading: isLoadingKredit } = useAppQuery<{
    data: Kredit[];
  }>(`user-kudykredit-list-${user.id}`, {
    url: `v1/user/kudykredit/history?limit=50`,
  });

  const isLoading =
    isLoadingAllowance || isLoadingKredit || isLoadingKreditBalance;

  const loanableAmount = allowance?.amount || 0;

  const isEligible = loanableAmount >= 50000;

  const kreditTaken = kreditBalance?.balance || 0;

  const hasTakenKredit = !!allKredit?.data?.length;

  const showKreditMenu = isEligible || hasTakenKredit;

  const activeKredits =
    allKredit?.data.filter((kredit) => kredit.status === 'running') || [];

  isKreditEnabled = activeKredits.length > 0;

  return {
    showKreditMenu,
    isEligible,
    loanableAmount,
    kreditTaken,
    isLoading,
    hasTakenKredit,
    activeKredits,
    isKreditEnabled,
  };
};

export default useKredit;
