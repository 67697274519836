import { useAppSelector } from '@/redux/typedHooks';
import { Outlet, Navigate } from 'react-router-dom';

const RestrictedRoutes = () => {
  const { token } = useAppSelector((state) => state.auth);

  return !token ? <Navigate to="/login" /> : <Outlet />;
};

export default RestrictedRoutes;
