import Snowfall from 'react-snowfall';
import './Modal.scss';
import useEscapeKey from '../../hooks/useEscapeKey';
import { useMediaQuery } from 'react-responsive';
import KudyLogo from '../KudyLogo';

const AppMenuModalNG = ({
  isOpen,
  isDecember,
  closeModal,
  settings,
  component,
}) => {
  useEscapeKey(closeModal, null, true);
  const isSmallPhone = useMediaQuery({
    maxWidth: 350,
  });

  return (
    <div
      className={`${isOpen ? 'enter z-10' : '-z-1'} modal fixed inset-0 flex h-full flex-col bg-ebonyclay px-5 pt-10 text-white md:px-30 lg:hidden lg:px-47 xl:px-90 2xl:px-120`}
    >
      {isDecember && (
        <Snowfall color={settings.color} snowflakeCount={settings.snowCount} />
      )}
      <KudyLogo />
      <section className="flex-1">
        <div className="relative flex h-full flex-col items-center justify-center text-center">
          <div className="component max-w-xl md:w-[45%] xl:w-2/5">
            {component}
          </div>
        </div>
      </section>
      <div className={`mb-6 text-center ${isSmallPhone ? 'mt-7' : 'mt-12'}`}>
        <p className="text-sm font-light">
          Kudy Financials Limited is licensed by SEC Nigeria.
        </p>
      </div>
    </div>
  );
};

export default AppMenuModalNG;
