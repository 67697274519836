import { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { FlagsProvider } from 'flagged';
import { QueryClient, QueryClientProvider } from 'react-query';
import Footer from './components/Footer/Footer';
import FullPageLoader from './components/Loaders/FullPageLoader';
import Routes from './routes/Routes';
import CustomToastContainer from './components/CustomToastContainer';
import Modal from './components/Modal/Modal';
import Prompt from './components/Modal/Prompt';
import ErrorBoundary from './components/ErrorBoundary';
import store from './redux/store';
import Contact from './components/Contact/Contact';
import GlobalScrollToTop from './components/GlobalScrollToTop';
import jsonLdData from './utils/jsonLdData';
import { createPortal } from 'react-dom';
import isNGDomain from './utils/isNGDomain';
import SentryFeedbackButton from './components/SentryFeedbackButton';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 3600000,
      retry: false,
    },
  },
});

const App = () => {
  const location = useLocation();
  const contactLocations = [
    '/',
    '/faq',
    '/terms',
    '/privacy',
    '/anti-money',
    '/solicitationterms',
  ];
  const footerLocations = [...contactLocations, '/login', '/register'];

  return (
    <>
      <ErrorBoundary>
        <FlagsProvider
          features={{
            kudyKredit: false,
            accountStatement: true,
            cryptoOption: false,
            kudyPoints: true,
            profileEditRegulation: true,
            permissions: true,
          }}
        >
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <CustomToastContainer />
              <Suspense fallback={<FullPageLoader />}>
                <Modal />
                <Prompt />
                <GlobalScrollToTop />
                <SentryFeedbackButton />
                <main className="bg-ebonyclay font-lato">
                  <Routes />
                </main>
                {contactLocations.includes(location.pathname) &&
                  !isNGDomain && <Contact />}
                {footerLocations.includes(location.pathname) && !isNGDomain && (
                  <Footer />
                )}
              </Suspense>
            </QueryClientProvider>
          </Provider>
        </FlagsProvider>
      </ErrorBoundary>
      {jsonLdData.map((data, index) =>
        createPortal(
          <script
            key={index}
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
          />,
          document.body
        )
      )}
    </>
  );
};

export default App;
