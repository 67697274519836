import type { ComponentPropsWithoutRef } from 'react';

const TransactionsIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 14.5V5.5C19 4.67157 18.3284 4 17.5 4H6.5C5.67157 4 5 4.67157 5 5.5V18.5C5 19.3284 5.67157 20 6.5 20H13.5C14.3284 20 15 19.3284 15 18.5C15 17.1193 16.1193 16 17.5 16C18.3284 16 19 15.3284 19 14.5ZM18.5014 16.7913C18.1948 16.9255 17.8561 17 17.5 17C16.6716 17 16 17.6716 16 18.5C16 18.8561 15.9255 19.1948 15.7913 19.5014C16.9874 18.9526 17.9526 17.9874 18.5014 16.7913ZM4 5.5C4 4.11929 5.11929 3 6.5 3H17.5C18.8807 3 20 4.11929 20 5.5V14.5C20 18.0899 17.0899 21 13.5 21H6.5C5.11929 21 4 19.8807 4 18.5V5.5ZM8.5 9C8.22386 9 8 8.77614 8 8.5C8 8.22386 8.22386 8 8.5 8H15.5C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9H8.5ZM8.5 12C8.22386 12 8 11.7761 8 11.5C8 11.2239 8.22386 11 8.5 11H15.5C15.7761 11 16 11.2239 16 11.5C16 11.7761 15.7761 12 15.5 12H8.5ZM8.5 15C8.22386 15 8 14.7761 8 14.5C8 14.2239 8.22386 14 8.5 14H13.5C13.7761 14 14 14.2239 14 14.5C14 14.7761 13.7761 15 13.5 15H8.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TransactionsIcon;
