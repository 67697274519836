import type { MouseEvent } from 'react';

const handleClickModalOverlay = <T>(
  e: MouseEvent<HTMLElement>,
  handleCloseFunc: (arg?: T) => void,
  functionArg?: T
) => {
  if (e.target !== e.currentTarget) return;
  if (functionArg) {
    handleCloseFunc(functionArg);
  } else {
    handleCloseFunc();
  }
};

export default handleClickModalOverlay;
