import { theme } from 'twin.macro';

const Warning = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.384 2.532C13.038 1.878 11.962 1.878 11.616 2.532L2.616 19.532C2.53499 19.6844 2.49482 19.8551 2.49942 20.0276C2.50403 20.2001 2.55323 20.3685 2.64226 20.5164C2.73128 20.6642 2.85708 20.7864 3.0074 20.8712C3.15772 20.956 3.32744 21.0003 3.5 21H21.5C21.6725 21.0004 21.8421 20.956 21.9923 20.8713C22.1425 20.7866 22.2682 20.6644 22.3571 20.5167C22.4461 20.3689 22.4952 20.2006 22.4997 20.0282C22.5043 19.8559 22.464 19.6852 22.383 19.533L13.384 2.532ZM13.5 18H11.5V16H13.5V18ZM11.5 14V9H13.5L13.501 14H11.5Z"
        fill={`${theme`colors.valencia.DEFAULT`}`}
      />
    </svg>
  );
};

export default Warning;
