import type { ComponentPropsWithoutRef } from 'react';

const PortfolioIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.2929 8H16.5C16.2239 8 16 7.77614 16 7.5C16 7.22386 16.2239 7 16.5 7H20.5C20.7761 7 21 7.22386 21 7.5V11.5C21 11.7761 20.7761 12 20.5 12C20.2239 12 20 11.7761 20 11.5V8.70711L14.8536 13.8536C14.6583 14.0488 14.3417 14.0488 14.1464 13.8536L11.5 11.2071L7.85355 14.8536C7.65829 15.0488 7.34171 15.0488 7.14645 14.8536C6.95118 14.6583 6.95118 14.3417 7.14645 14.1464L11.1464 10.1464C11.3417 9.95118 11.6583 9.95118 11.8536 10.1464L14.5 12.7929L19.2929 8ZM20.5 18C20.7761 18 21 18.2239 21 18.5C21 18.7761 20.7761 19 20.5 19H5.5C4.11929 19 3 17.8807 3 16.5V7.5C3 7.22386 3.22386 7 3.5 7C3.77614 7 4 7.22386 4 7.5V16.5C4 17.3284 4.67157 18 5.5 18H20.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PortfolioIcon;
