import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [
      Sentry.feedbackIntegration({
        autoInject: false,
        isNameRequired: true,
        isEmailRequired: true,
        showBranding: false,
        triggerLabel: 'Report an issue',
        triggerAriaLabel: 'Report an issue',
        formTitle: 'Report an issue',
        emailPlaceholder: 'Your Email',
        submitButtonLabel: 'Send FeedBack',
        messagePlaceholder: 'What is the issue? What did you expect?',
        colorScheme: 'light',
      }),
      Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
