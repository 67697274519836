import { useAppSelector } from '@/redux/typedHooks';
import { useAppQuery } from './useAppQuery';
import type Permission from '@/types/Permission';
import type Transaction from '@/types/Transaction';

const useGetStarted = () => {
  const { user } = useAppSelector(({ auth }) => auth);
  const { data: kycUpdate, isLoading: isLoadingKyc } = useAppQuery<{
    data: Permission;
  }>(`user-permissions-kyc-update-${user.id}`, {
    url: `v1/user/permissions?name=kyc_update`,
  });
  const { data: fundTransactions, isLoading: isLoadingTransactions } =
    useAppQuery<{ data: Transaction[] }>(`user-fund-transactions-${user.id}`, {
      url: 'v1/user/transactions?type=fund',
    });

  const isLoading = isLoadingKyc || isLoadingTransactions;
  const isVerified = !!kycUpdate?.data.status;
  const isInvested = !!fundTransactions?.data.length;
  const showGetStarted = isLoading ? false : !isInvested;

  return { isVerified, showGetStarted, isLoading };
};

export default useGetStarted;
