import Snowfall from 'react-snowfall';
import './Modal.scss';
import useEscapeKey from '../../hooks/useEscapeKey';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import KudyLogo from '../KudyLogo';
import twMerge from '@/utils/twMerge';

const AppMenuModal = ({
  isOpen,
  isDecember,
  closeModal,
  settings,
  component,
}) => {
  useEscapeKey(closeModal, null, true);
  const navigate = useNavigate();
  const isSmallPhone = useMediaQuery({
    maxWidth: 350,
  });

  return (
    <div
      className={` ${isOpen ? 'z-10' : '-z-1'} modal fixed inset-0 flex h-full flex-col bg-ebonyclay px-5 pt-10 text-white md:px-30 lg:px-47 xl:px-90 2xl:px-120`}
    >
      {isDecember && (
        <Snowfall color={settings.color} snowflakeCount={settings.snowCount} />
      )}
      <KudyLogo />
      <section className="flex-1">
        <div className="relative flex h-full flex-col items-center justify-center text-center">
          <div className="component max-w-xl md:w-[45%] xl:w-2/5">
            {component}
          </div>
          <div
            className={twMerge(
              'absolute bottom-0 flex flex-wrap justify-center gap-7 capitalize md:hidden'
            )}
          >
            <button
              onClick={() => closeModal(() => navigate('/register'), true)}
              className={`${
                isSmallPhone ? 'px-6 py-2' : 'px-38 py-[11px]'
              } hover-linear-background-transparent modal-slideInUp`}
            >
              Register
            </button>
            <button
              onClick={() => closeModal(() => navigate('/login'), true)}
              className={`${
                isSmallPhone ? 'px-6 py-2' : 'px-38 py-[11px]'
              } hover-linear-background-coloured modal-slideInUp`}
            >
              Sign in
            </button>
          </div>
        </div>
      </section>
      <div
        className={`mb-6 flex w-full flex-col justify-between md:flex-row md:items-center ${
          isSmallPhone ? 'mt-7' : 'mt-12'
        }`}
      >
        <div className="flex justify-between md:justify-around">
          <div className="modal-slideInUp cursor-pointer">
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://twitter.com/kudyfinancials'}
              className="hover-underline-jungleGreen mr-30 py-2"
            >
              Twitter
            </a>
          </div>
          <div className="modal-slideInUp cursor-pointer">
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://instagram.com/kudyfinancials'}
              className="hover-underline-jungleGreen py-2"
            >
              Instagram
            </a>
          </div>
        </div>
        <p className="modal-slideInUp hidden md:block">
          © {new Date().getFullYear()} Kudy Financials S.à.r.l. All rights
          reserved.
        </p>
      </div>
    </div>
  );
};

export default AppMenuModal;
