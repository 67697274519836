import getDomain from './getDomain';
import getSupportEmail from './getSupportEmail';
import isNGDomain from './isNGDomain';

// JSON-LD markup generated by Google Structured Data Markup Helper
const io = [
  {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: 'KUDY FINANCIALS S.À.R.L',
    image: `https://${getDomain()}/assets/images/logo-2x.svg`,
    email: `${getSupportEmail()}`,
    address: {
      '@type': 'PostalAddress',
      streetAddress:
        "Kudy Financials S.à.r.l. BP 027 West Side Village 2 C Parc d'Activités Capellen L-8308 Luxembourg.",
      addressCountry: 'Luxembourg',
    },
  },
  {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: 'KUDY FINANCIALS S.À.R.L',
    image: `https://${getDomain()}/assets/images/logo-2x.svg`,
    email: `${getSupportEmail()}`,
    address: {
      '@type': 'PostalAddress',
      streetAddress:
        '1 Scott Place, 2 Hardman Street, Manchester, M3 3AA, United Kingdom',
      addressCountry: 'United Kingdom',
    },
  },
];

const ng = [
  {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    name: 'KUDY FINANCIALS LIMITED',
    image: `https://${getDomain()}/assets/images/logo-2x.svg`,
    email: `${getSupportEmail()}`,
    address: {
      '@type': 'PostalAddress',
      streetAddress: '8, Lake Chad Crescent, Maitama, Abuja, Nigeria',
      addressCountry: 'Nigeria',
    },
  },
];

export default isNGDomain ? ng : io;
