import type {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query';
import { useQuery, useMutation } from 'react-query';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import createAxiosInstance from '../utils/createAxiosInstance';
import type ErrorResponse from '@/types/ErrorResponse';
import { Toast } from '@/helpers/toastify.helpers';
import { useAppSelector } from '@/redux/typedHooks';

type AxiosResType<TData> = {
  data: TData;
};
type RemoveSomeQueryOptions<TQueryOptions> = Omit<
  TQueryOptions,
  'queryKey' | 'queryFn' | 'select'
>;
type CustomErrorOptions<TError, TVariables, TContext> = {
  customErrorToastFn?: (message: string) => void;
  customErrorAction?: (
    error: TError,
    variables: TVariables,
    context: TContext | undefined
  ) => void;
};
type AppError = AxiosError<ErrorResponse>;

const fetch = async <TData>(
  axiosOptions: AxiosRequestConfig,
  signal: AbortSignal | undefined
) => {
  const axiosInstance = createAxiosInstance();

  const { data }: AxiosResType<TData> = await axiosInstance({
    method: 'GET',
    signal,
    ...axiosOptions,
  });

  return data;
};

const appMutate = async <TData>(
  axiosOptions: AxiosRequestConfig,
  requestBody: unknown
) => {
  const axiosInstance = createAxiosInstance();

  const { data }: AxiosResType<TData> = await axiosInstance({
    method: 'POST',
    data: requestBody,
    ...axiosOptions,
  });

  return data;
};

export const useAppQuery = <TData>(
  queryKey: QueryKey,
  axiosOptions: AxiosRequestConfig,
  queryOptions?: RemoveSomeQueryOptions<UseQueryOptions<TData, AppError>>
) => {
  const { token } = useAppSelector((state) => state.auth);

  return useQuery<TData, AppError>(
    queryKey,
    ({ signal }) => fetch<TData>(axiosOptions, signal),
    { enabled: !!token, ...queryOptions }
  );
};

export const useAppMutation = <TData, TVariables = unknown, TContext = unknown>(
  axiosOptions: AxiosRequestConfig,
  {
    customErrorToastFn,
    customErrorAction,
    ...queryOptions
  }: RemoveSomeQueryOptions<
    UseMutationOptions<TData, AppError, TVariables, TContext>
  > &
    CustomErrorOptions<AppError, TVariables, TContext> = {}
) =>
  useMutation<TData, AppError, TVariables, TContext>(
    (requestBody) => appMutate<TData>(axiosOptions, requestBody),
    {
      onError: (error, variables, context) => {
        const { message = 'Process failed. Please refresh' } =
          error?.response?.data || {};
        if (customErrorToastFn) return customErrorToastFn(message);
        Toast({
          message,
          type: 'error',
        });
        if (customErrorAction) customErrorAction(error, variables, context);
      },
      ...queryOptions,
    }
  );
