import useEscapeKey from '../../hooks/useEscapeKey';
import handleClickModalOverlay from '../../utils/handleClickModalOverlay';

const SideModal = ({ isOpen, closeModal, scrollable, component }) => {
  useEscapeKey(closeModal, null);

  return (
    <section
      className={`${
        isOpen
          ? 'transition-opacity-z-index-active z-50 opacity-100'
          : 'transition-opacity-z-index -z-1 opacity-0'
      } absolute right-0 top-0 h-screen w-screen bg-uberblue/30 backdrop-blur-sm`}
      onClick={(e) => handleClickModalOverlay(e, closeModal, null)}
    >
      <div
        className={`modal ${isOpen ? 'enter' : 'opacity-0'} ${
          scrollable ? 'overflow-y overflow-x-hidden' : ''
        } absolute right-0 top-0 mb-72 h-screen w-full bg-white md:w-520`}
        style={{ cursor: 'auto' }}
      >
        <div className="font-lato text-ebonyclay">{component}</div>
      </div>
    </section>
  );
};

export default SideModal;
