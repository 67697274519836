import PublicRoutes from '@/components/PublicRoutes';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Home = lazy(() => import('../pages/Home/Home'));
const Login = lazy(() => import('../pages/Login/Login'));
const Register = lazy(() => import('../pages/Register/Register'));

const Privacy = lazy(() => import('../pages/Privacy/Privacy'));
const AntiMoney = lazy(() => import('../pages/AntiMoney/AntiMoney'));
const Terms = lazy(() => import('../pages/Terms/Terms'));

const HomeNG = lazy(() => import('../pages/Home/HomeNG'));
const LoginNG = lazy(() => import('../pages/Login/LoginNG'));
const RegisterNG = lazy(() => import('../pages/Register/RegisterNG'));
const BusinessRegisterNG = lazy(
  () => import('../pages/Register/BusinessRegisterNG')
);

const PrivacyNG = lazy(() => import('../pages/Privacy/PrivacyNG'));
const AntiMoneyNG = lazy(() => import('../pages/AntiMoney/AntiMoneyNG'));
const TermsNG = lazy(() => import('../pages/Terms/TermsNG'));
const ComplaintNG = lazy(() => import('../pages/Complaint/ComplaintNG'));

const Faq = lazy(() => import('../pages/Faq/Faq'));
const SolicitationTerms = lazy(() => import('../components/SolicitationTerms'));

export const ioRoutes = (
  <>
    <Route path="/" element={<PublicRoutes />}>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
    </Route>

    <Route path="/privacy" element={<Privacy />} />
    <Route path="/anti-money" element={<AntiMoney />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="/faq" element={<Faq />} />
    <Route path="/solicitationterms" element={<SolicitationTerms />} />
  </>
);

export const ngRoutes = (
  <>
    <Route path="/" element={<PublicRoutes />}>
      <Route path="/" element={<HomeNG />} />
      <Route path="/login" element={<LoginNG />} />
      <Route path="/register" element={<RegisterNG />} />
      <Route path="/businessregister" element={<BusinessRegisterNG />} />
    </Route>

    <Route path="/privacy" element={<PrivacyNG />} />
    <Route path="/anti-money" element={<AntiMoneyNG />} />
    <Route path="/terms" element={<TermsNG />} />
    <Route path="/complaint" element={<ComplaintNG />} />
  </>
);
