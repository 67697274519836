import type { ToastOptions } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Warning from '../components/Icons/Warning';
import CheckFill from '@/components/Icons/CheckFill';
import type { ReactNode } from 'react';

const Message = ({ message }: { message: ReactNode }) => (
  <div className="w-full font-lato text-base font-normal text-ebonyclay">
    {message}
  </div>
);

type Options = {
  message: ReactNode;
  type: 'success' | 'error';
};

export const Toast = ({ type, message }: Options) => {
  const options: ToastOptions = {
    closeButton: false,
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    icon: type === 'error' ? <Warning /> : <CheckFill />,
  };

  toast[type](<Message message={message} />, options);
};
