import { useAppQuery } from './useAppQuery';
import type Exchange from '@/types/Exchange';

const initial = {
  buy: 0,
  sell: 0,
  created_at: new Date().toISOString(),
};

export const useExchange = () => {
  const { data } = useAppQuery<{ data: Exchange }>('exchange', {
    url: `v1/exchanges/latest`,
  });

  return data?.data || initial;
};
