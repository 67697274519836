import { useAppQuery } from './useAppQuery';
import { useAppSelector } from '@/redux/typedHooks';
import type EDD from '@/types/EDD';
import type Flags from '@/types/Flags';

const useEnhancedDueDiligence = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { data: userFlags } = useAppQuery<Flags>(`user-flags-${user.id}`, {
    url: `v1/user/flags`,
  });

  const { data, isLoading } = useAppQuery<EDD>(`user-edd-${user.id}`, {
    url: `v1/user/edd`,
  });
  const formContent = data?.content;
  const isIncompleteEddForm =
    formContent && Object.keys(formContent).length > 0;
  const isEddDue = formContent?.flag === 'edd';
  const isPepEddDue = formContent?.flag === 'pep';

  const flags = userFlags?.flags || [];
  const isFlagged = flags.length > 0;

  return { isFlagged, isEddDue, isPepEddDue, isLoading, isIncompleteEddForm };
};

export default useEnhancedDueDiligence;
