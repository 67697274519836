type LineProps = {
  color?: 'white' | 'gray';
};

const Line = ({ color = 'white' }: LineProps) => {
  const colors = {
    white: 'bg-white',
    gray: 'bg-[#8282824d]',
  };

  return (
    <div className={`wow animated__middleOut mb-10 h-0.5 ${colors[color]}`} />
  );
};

export default Line;
