import { lazy, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import RestrictedRoutes from '../components/RestrictedRoutes';
import useSideMenuRoutes from './useSideMenuRoutes';
import useGetUser from '../hooks/useGetUser';
import useEnhancedDueDiligence from '@/hooks/useEnhancedDueDiligence';
import FullPageLoader from '@/components/Loaders/FullPageLoader';
import isNGDomain from '@/utils/isNGDomain';
import { ioRoutes, ngRoutes } from './publicRoutes';
import { useAppSelector } from '@/redux/typedHooks';

const PrivateRoutes = lazy(() => import('../components/PrivateRoutes'));
const NotFound = lazy(() => import('../pages/NotFound/NotFound'));
const KudyKreditAgreement = lazy(
  () => import('../pages/Terms/KudyKreditAgreement')
);
const Indemnity = lazy(
  () => import('../components/AccountStatement/Indemnity')
);
const DownloadTransaction = lazy(
  () => import('../components/DownloadTransaction')
);
const FundsLayout = lazy(() => import('../components/Funds/FundsLayout'));
const FundsView = lazy(() => import('../pages/Funds/FundsView'));
const EnhancedDueDiligence = lazy(
  () => import('../pages/EnhancedDueDiligence/EnhancedDueDiligence')
);
const POPForm = lazy(() => import('../pages/POPForm/POPForm'));

const AppRoutes = () => {
  useGetUser();

  const routes = useSideMenuRoutes().filter((route) => route.enabled);

  const flatRoutes = routes.filter((route) => route.title !== 'Funds');
  const fundsRoute = routes.find((route) => route.title === 'Funds');

  const { isIncompleteEddForm, isLoading: isLoadingEdd } =
    useEnhancedDueDiligence();

  const { token, user } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  const popBannerClosedPermanent = localStorage.getItem(
    `pop-bannerClosed-${user.id}`
  );

  useEffect(() => {
    if (token) {
      document.documentElement.style.setProperty('--feedback-fg', '#283445');
      document.documentElement.style.setProperty('--feedback-bg', '#ffffff');
    } else {
      document.documentElement.style.setProperty('--feedback-fg', '#ffffff');
      document.documentElement.style.setProperty('--feedback-bg', '#283445');
    }
  }, [token]);

  useEffect(() => {
    if (pathname === '/') {
      document.documentElement.style.setProperty(
        '--feedback-margin',
        '60px 15px'
      );
    } else {
      document.documentElement.style.setProperty('--feedback-margin', '15px');
    }
  }, [pathname]);

  return (
    <Routes>
      {isNGDomain ? ngRoutes : ioRoutes}

      <Route path="/" element={<RestrictedRoutes />}>
        <Route path="/kudykreditagreement" element={<KudyKreditAgreement />} />
        <Route path="/indemnity" element={<Indemnity />} />
        <Route path="/statement/:userId" element={<DownloadTransaction />} />
      </Route>

      <Route path="/" element={<PrivateRoutes />}>
        {flatRoutes.map(({ to, title, Component }, key) => (
          <Route path={to} key={`${key}-${title}`} element={<Component />} />
        ))}
        {fundsRoute && (
          <Route path={fundsRoute.to} element={<FundsLayout />}>
            <Route index element={<fundsRoute.Component />} />
            <Route path="growth" element={<FundsView />} />
            <Route path="prime" element={<FundsView />} />
          </Route>
        )}
        {(isIncompleteEddForm || isLoadingEdd) && (
          <Route
            path="/edd"
            element={
              isLoadingEdd ? <FullPageLoader /> : <EnhancedDueDiligence />
            }
          />
        )}
        {!popBannerClosedPermanent && (
          <Route path="/pop" element={<POPForm />} />
        )}
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
